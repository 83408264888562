<template>
  <vue-perfect-scrollbar class="h-without-header">
    <v-list dense rounded class="pa-4">
      <v-list-item-group>
        <v-list-item
          v-for="(item, key) in computedSettingsItem"
          :key="key"
          color="blue"
          @click="$router.push(item.path)"
        >
          <v-list-item-content class="ma-1">
            <div class="fs-11 fw-bold px-2">
              {{ definition[item.key2] }}
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="organization.settingPaidByCustomer && checkPrivilege('transfer_fee:read')"
          :key="null"
          color="blue"
          @click="$router.push('/transfer-fee')"
        >
          <v-list-item-content class="ma-1">
            <div class="fs-11 fw-bold px-2">振込手数料</div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="checkPrivilege('company:read')"
          :key="null"
          color="blue"
          @click="$router.push('/company')"
        >
          <v-list-item-content class="ma-1">
            <div class="fs-11 fw-bold px-2">請求元｜学習データ</div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </vue-perfect-scrollbar>
</template>

<script>
export default {
  props: {
    organization: {
      type: Object,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      settingsItems: [
        {
          text: '勘定科目',
          path: '/accounttitle',
          key: 'settingsAccountTitle',
          key2: 'account_title',
          abilityKey: 'account_title:read',
        },
        {
          text: '部門',
          path: '/department',
          key: 'settingsDepartment',
          key2: 'department',
          abilityKey: 'department:read',
        },
        {
          text: '取引先',
          path: '/vendor',
          key: 'settingsVendor',
          key2: 'vendor',
          abilityKey: 'vendor:read',
        },
        {
          text: 'プロジェクト',
          path: '/project',
          key: 'settingsProject',
          key2: 'project',
          abilityKey: 'project:read',
        },
        {
          text: 'セグメント',
          path: '/segment',
          key: 'settingsSegment',
          key2: 'segment',
          abilityKey: 'segment:read',
        },
        {
          text: '品目',
          path: '/item',
          key: 'settingsItem',
          key2: 'item',
          abilityKey: 'item:read',
        },
        {
          text: 'メモタグ',
          path: '/tag',
          key: 'settingsTag',
          key2: 'tag',
          abilityKey: 'tag:read',
        },
        {
          text: '口座',
          path: '/walletable',
          key: 'settingsWalletable',
          key2: 'walletable',
          abilityKey: 'walletable:read',
        },
        {
          text: '税区分',
          path: '/taxclass',
          key: 'settingsTax',
          key2: 'tax_class',
          abilityKey: 'tax:read',
        },
      ],
    }
  },
  computed: {
    computedSettingsItem: function () {
      return this.settingsItems.filter((item) =>
        this.checkPrivilege(item.abilityKey)
      )
    },
  },
  beforeMount() {
    this.settingsItems = this.filterByOrganizationSettings(this.settingsItems)
  },
  methods: {
    getScrollHeight() {
      return 'height:calc(100vh - 150px)'
    },
    checkSelected(path) {
      try {
        var routePath = this.$route.path
        if (routePath) {
          return path === routePath
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    },
    filterByOrganizationSettings(items) {
      return items.filter((item) => {
        //部門は常に表示
        if (item.key === 'settingsDepartment') {
          return true
        } else {
          return item.key === null || this.organization[item.key] !== 0
        }
      })
    },
  },
}
</script>
