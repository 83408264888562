<template>
  <v-container v-if="checkPrivilege('master:read')" fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12" md="2" sm="6">
        <master-sidebar :organization="organization" :definition="definition" />
      </v-col>
      <v-col cols="12" md="10" sm="6" class="border-left-1">
        <router-view
          :get-journal-items="getJournalItems"
          :get-master="getMaster"
          :export-master="exportMaster"
          :sort-master="sortMaster"
          :organization="organization"
          :definition="definition"
          :bank-data="bankData"
          :journal-items="journalItems"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MasterSidebar from '@/pages/master/@components/MasterSidebar/MasterSidebar'

export default {
  components: {
    MasterSidebar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    getJournalItems: {
      type: Function,
      required: true,
    },
    getMaster: {
      type: Function,
      required: true,
    },
    exportMaster: {
      type: Function,
      required: true,
    },
    sortMaster: {
      type: Function,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
    bankData: {
      type: Object,
      required: true,
    },
    journalItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
